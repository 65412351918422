export function buildQueryString(data, parentKey = null) {
    const queryParts = [];

    for (let key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const value = data[key];

            if (parentKey) {
                key = `${parentKey}[${key}]`;
            }

            if (typeof value === 'object' && value !== null) {
                queryParts.push(buildQueryString(value, key));
            } else {
                const encodedKey = encodeURIComponent(key);
                const encodedValue = encodeURIComponent(value);
                queryParts.push(`${encodedKey}=${encodedValue}`);
            }
        }
    }

    return queryParts.join('&');
}
